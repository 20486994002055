import React, { useEffect, useRef, useState } from 'react'
import './ResetForgotPassword.less'
import BackgroundWrapper from '../../components/backgroundWrapper/BackgroundWrapper'
// import LHSRHSWrapper from '../../components/backgroundWrapper/LHSRHSWrapper'
import resetForgotPasswordIcon from '../../assets/icon/forgotPassword/resetForgotPassword.svg'
import PasswordForm from '../signin/passwordForm/PasswordForm'
import ReCaptchaTwo from '../../components/recaptcha/ReCaptchaTwo'
import { getDecryptedData } from '../../services/crypto'
import { Button, message, Typography, Row,Col } from 'antd'
import moment from 'moment'
import LHSCard from '../../components/LHSCard/LHSCard'
import RHSCard from '../../components/RHSCard/RHSCard'
import { resetForgotUserPassword } from '../../services/user'
import { useTranslation } from 'react-i18next'
import ProSkeleton from '@ant-design/pro-skeleton'
import ErrorPage from '../../components/errorPage/ErrorPage'
import invalidIcon from '../../assets/icon/common/invalidLink.svg'
import { useHistory } from 'react-router-dom'
import CommonSkeleton from '../../components/CommonSkeleton/CommonSkeleton'
import { Helmet } from 'react-helmet'
import CommonSignUpBackground from '../../components/CommonSignUpBackground/CommonSignUpBackground'
const { Paragraph } = Typography

const errorType = {
  //   invalidLink: 'INVALID_LINK',
  expiredTimeStamp: 'EXPIRED_TIME_STAMP',
  //   commonError: 'COMMMON_ERROR',
}

const ResetForgotPassword = () => {
  const { t } = useTranslation()
  const recaptchRef = useRef()
  const [errorState, setErrorState] = useState('')
  const [isLoading, setIsLoading] = useState('initialLoad')
  const [passwordToken, setPasswordToken] = useState('')
  const [isPasswordReset, setIsPasswordReset] = useState(false)
  const history = useHistory()

  useEffect(() => {
    decryptedTokenData()
  }, [])

  const decryptedTokenData = async () => {
    const data = new URLSearchParams(window.location.search).get('i')
    const recaptchaToken = await recaptchRef.current.generateToken()
    getDecryptedData({ cryptoValues: [data] }, recaptchaToken)
      .then((result) => {
        if (
          result &&
          result.status === 200 &&
          result.data.decryptedValues &&
          result.data.decryptedValues.length &&
          result.data.decryptedValues[0]
        ) {
          let forgotPasswordParams = JSON.parse(result.data.decryptedValues[0])
          let currentTimeStamp = moment()
          if (
            moment(currentTimeStamp).isAfter(
              moment(forgotPasswordParams.expiryTimeStamp)
            )
          ) {
            setErrorState(errorType.expiredTimeStamp)
          } else {
            setPasswordToken(forgotPasswordParams.passwordToken)
          }
        } else {
          setErrorState(errorType.expiredTimeStamp)
        }
      })
      .catch(() => {
        setErrorState(errorType.expiredTimeStamp)
      })
      .finally(() => {
        setIsLoading('')
      })
  }

  const saveNewPassword = (password) => {
    setIsLoading('update')

    resetForgotUserPassword({ password }, passwordToken)
      .then((result) => {
        if (result && result.status === 200) {
          setIsPasswordReset(true)
        } else if (result && result.status === 401) {
          setErrorState(errorType.expiredTimeStamp)
        } else {
          message.error(t('common.errorMessage'))
        }
      })
      .catch((error) => {
        if (error && error.response.status === 401) {
          setErrorState(errorType.expiredTimeStamp)
        } else {
          message.error(t('common.errorMessage'))
        }
      })
      .finally(() => {
        setIsLoading('')
      })
  }
  const goToLoginPage = () => {
    history.push('/login')
  }
  return (
    <>
      <ReCaptchaTwo ref={recaptchRef} />
      {isLoading === 'initialLoad' && <CommonSkeleton />}
      {(!isLoading || isLoading !== 'initialLoad') && (
        <CommonSignUpBackground signInFlow={true}>
          <Row className="resetForgotPasswordContainer">
            {errorState === errorType.expiredTimeStamp && !isLoading && (
              <Row className='w-full' justify={'center'}>
                <ErrorPage
                  icon={invalidIcon}
                  title={t('forgotPassword.linkExpired')}
                  subTitle={t('forgotPassword.returnToLoginMessage')}
                  // extra={
                  //   <Button onClick={goToLoginPage} type="primary">
                  //     {t('common.tryAgain')}
                  //   </Button>
                  // }
                />
              </Row>
            )}
            {(!errorState || errorState !== errorType.expiredTimeStamp) && (
              <>
                {!errorState &&
                  (!isLoading || isLoading !== 'initialLoad') &&
                  isPasswordReset && (
                    <>
                      <div className="titleText">
                        {t('forgotPassword.resetPasswordSuccessfully')}
                      </div>
                      <Col span={24}>
                      <Paragraph className="passwordResetMessage">
                        {t('forgotPassword.resetPasswordMessage')}
                      </Paragraph>
                      </Col>
                        
                      <Button type="primary" onClick={goToLoginPage}>
                        {t('forgotPassword.retrunToLogin')}
                      </Button>
                    </>
                  )}
                {!errorState &&
                  // (!isLoading || isLoading !== 'initialLoad') &&
                  !isPasswordReset && (
                    <>
                      <Helmet>
                        <title>{t('browserTitle.resetPassword')}</title>
                      </Helmet>
                      <PasswordForm
                        saveNewPasswordCallback={saveNewPassword}
                        isLoading={!!isLoading}
                        title={t('resetPassword.setNewPassword')}
                        subTitle={t('resetPassword.setNewPasswordSubtitle')}
                        isTwoButton
                      />
                    </>
                  )}
              </>
            )}
          </Row>
        </CommonSignUpBackground>

        // <BackgroundWrapper>
        //   <LHSRHSWrapper
        //     isRHSOnly={
        //       errorState === errorType.expiredTimeStamp ||
        //       isLoading === 'initialLoad'
        //     }
        //     lhsContent={
        //       // (!isLoading || isLoading !== 'initialLoad') && (
        //       <LHSCard icon={resetForgotPasswordIcon} />
        //       // )
        //     }
        //     rhsContent={
        //       <div className="resetForgotPasswordContainer">
        //         {errorState === errorType.expiredTimeStamp && !isLoading && (
        //           <ErrorPage
        //             icon={invalidIcon}
        //             title={t('forgotPassword.linkExpired')}
        //             subTitle={t('forgotPassword.returnToLoginMessage')}
        //             // extra={
        //             //   <Button onClick={goToLoginPage} type="primary">
        //             //     {t('common.tryAgain')}
        //             //   </Button>
        //             // }
        //           />
        //         )}
        //         {(!errorState || errorState !== errorType.expiredTimeStamp) && (
        //           <RHSCard>
        //             {!errorState &&
        //               (!isLoading || isLoading !== 'initialLoad') &&
        //               isPasswordReset && (
        //                 <>
        //                   <div className="titleText">
        //                     {t('forgotPassword.resetPasswordSuccessfully')}
        //                   </div>
        //                   <Paragraph className="passwordResetMessage">
        //                     {t('forgotPassword.resetPasswordMessage')}
        //                   </Paragraph>
        //                   <Button type="primary" onClick={goToLoginPage}>
        //                     {t('forgotPassword.retrunToLogin')}
        //                   </Button>
        //                 </>
        //               )}
        //             {!errorState &&
        //               // (!isLoading || isLoading !== 'initialLoad') &&
        //               !isPasswordReset && (
        //                 <>
        //                   <Helmet>
        //                     <title>{t('browserTitle.resetPassword')}</title>
        //                   </Helmet>
        //                   <PasswordForm
        //                     saveNewPasswordCallback={saveNewPassword}
        //                     isLoading={!!isLoading}
        //                     title={t('resetPassword.setNewPassword')}
        //                     isTwoButton
        //                   />
        //                 </>
        //               )}
        //           </RHSCard>
        //         )}
        //       </div>
        //     }
        //   ></LHSRHSWrapper>
        // </BackgroundWrapper>
      )}
    </>
  )
}

ResetForgotPassword.propTypes = {}

export default ResetForgotPassword
